<!-- 商品评论 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<!-- 头部 搜索 -->
		<button-search ref="buttonSearch" class="pt-3" placeholder="要搜索的商品名称" @search="searchEvent" :showSuperSearch="false">
			
		</button-search>

		<!-- 商品列表 -->
		<el-table :data="tableData" style="width: 100%" border class="mt-3">
			<el-table-column type="expand">
				<template slot-scope="scope">
					<!-- 评论 -->
					<div class="media">
						<img class="mr-3" :src="scope.row.user.avatar" alt="Generic placeholder image" style="height: 70px;width: 70px;border-radius: 100%;">
						<div class="media-body">
							<h6 class="mt-0 d-flex">
								<p>{{scope.row.user.nickname}}</p>
								<p style="padding-left: 10px;color: #878787;">{{scope.row.review}}</p>
								<el-button v-if="!scope.row.extra && !textareaEdit" class="ml-auto" type="info" size="mini" @click="textareaEdit = true">回复</el-button>
							</h6>
							{{scope.row.review.data}}
							<div class="py-2">
								<img v-for="(item,index) in scope.row.review.image" :key="index" :src="item" style="max-width: 100px;max-height: 100px;" />
							</div>

							<div v-if="textareaEdit">
								<el-input type="textarea" :rows="2" placeholder="请输入评价内容" v-model="textarea">
								</el-input>
								<div class="py-2">
									<el-button class="ml-auto mr-2" type="success" size="mini" @click="review(scope.row.id)">回复</el-button>
									<el-button class="ml-auto" type="info" size="mini" @click="closeTextarea">取消</el-button>
								</div>
							</div>

							<div v-if="scope.row.extra">
								<div class="media mt-3 bg-light p-2 rounded" v-for="(item,index) in scope.row.extra" :key="index">
									<div class="media-body">
										<h6 class="mt-0 d-flex">
											客服
											<el-button v-if="!textareaEdit" class="ml-auto" type="info" size="mini" @click="openTextarea(item.data)">修改</el-button>
										</h6>
										{{item.data}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</el-table-column>

			<!-- id -->
			<el-table-column label="ID" width="70" align="center" prop="id">
			</el-table-column>

			<!-- 商品信息 -->
			<el-table-column label="商品" width="380">
				<template slot-scope="scope">
					<div class="media">
						<img class="mr-3" style="width: 60px;height: 60px;" :src="scope.row.goods_item ? scope.row.goods_item.cover : ''">
						<div class="media-body">
							<p class="mt-0">{{scope.row.goods_item ? scope.row.goods_item.title : '商品已被删除'}}</p>
						</div>
					</div>
				</template>
			</el-table-column>

			<!-- 评价信息 -->
			<el-table-column width="250" label="评价信息">
				<template slot-scope="scope">
					<div class="d-flex flex-column">
						<p>用户名：{{scope.row.user.nickname}}</p>
						<p>评分：<el-rate v-model="scope.row.rating" disabled show-score text-color="#ff9900" score-template="{value}">
							</el-rate>
						</p>
					</div>
				</template>
			</el-table-column>

			<!-- 评级时间 -->
			<el-table-column prop="review_time" align="center" label="评价时间">
			</el-table-column>

			<!-- 是否显示 -->
			<el-table-column align="center" label="是否显示" width="150">
				<template slot-scope="scope">
					<el-button :type="scope.row.status ? 'success' : 'danger'" size="mini" @click="changeStatus(scope.row)" plain>{{scope.row.status ? '启用' : '禁用'}}
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 底部内容 -->
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0; left: 200px; right: 0;z-index: 100;">
			<!-- 底部分页 -->
			<div style="flex: 1;" class="px-2">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.current"
				 :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>
		</el-footer>

	</div>
</template>

<script>
	import buttonSearch from "@/components/common/button-search.vue" // 引入 按钮、搜索、高级搜索组件
	import common from "@/common/mixins/common.js" // 引入 全局共公样式
	export default {
		inject: ['layout'], // 注入layout.vue
		mixins: [common], // 引入mixins里的common.js
		// 引入注册
		components: {
			buttonSearch
		},
		data() {
			return {
				preUrl: "goods_comment", //api
				textarea: "",
				textareaEdit: false,
				tableData: [],
				title:""
			}
		},
		// 生命周期，调用__getData生成的数据
		created() {},
		// 生命周期监听页面
		methods: {
			// 客服留言 修改
			openTextarea(data) {
				this.textarea = data
				this.textareaEdit = true
			},
			// 评论 回复
			review(id) {
				if(this.textarea == ''){
					return this.$message({
						message: '回复内容不能为空',
						type: 'error',
					})
				}
				this.layout.showLoading()
				this.axios.post('/admin/goods_comment/review/' + id, {
					data: this.textarea
				}, {
					token: true
				}).then(res => {
					this.closeTextarea()
					this.$message({
						message: '回复成功',
						type: 'success',
					})
					this.getList() //重新获取数据
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 评论 取消
			closeTextarea() {
				this.textarea = ''
				this.textareaEdit = false
			},
			// 获取列表 可修改
			getListResult(e) {
				this.tableData = e.list
			},
			// 获取请求列表分页的url暴露在外面，方便单独修改
			getListUrl(){
				return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}&title=${this.title}` // 手动拼接通用api，把搜索的title加上
			},
			// 搜索事件
			searchEvent(e) {
				this.title = e
				this.getList() //调用common.js里的getList里的getListUrl(直接调整上面api即可)
			},
		}
	}
</script>

<style>
</style>
